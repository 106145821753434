import React from 'react';
import './App.css';
import SongList from './song-list/SongList';
import Song from './Song';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      done: null,
      locked: null,
      toBeDone: null,
      songs: null,
    };
  }

  async componentDidMount() {
    const songs = await Song.index()

    const done = songs.filter(song => song.Status === 'Kész');
    const locked = songs.filter(song => song.Status === 'Elkezdve');
    const toBeDone = songs.filter(song => song.Status === 'TBD');

    this.setState({
      songs,
      done,
      locked,
      toBeDone,
      selected: toBeDone,
    })
  }

  switchTab(list) {
    this.setState({ selected: list });
  }

  render() {
    const header = <header className=""><h1 className="text-3xl lg:text-4xl font-bold text-orange-600">Dallista importálás</h1></header>;

    if (!this.state.selected) {
      return (
        <div className="">
          {header}
        </div>
      );
    }

    const tabNames = ['Teendő (TBD)', 'Folyamatban', 'Kész']
    const tabs = [this.state.toBeDone, this.state.locked, this.state.done].map((list, index) => (
      <div
        key={index}
        onClick={() => this.switchTab(list)}
        className={'p-2 mx-4 border-b-2 cursor-pointer ' + (list === this.state.selected ? 'border-orange-400 text-orange-600' : 'border-gray-700')}
      >{tabNames[index]} ({list.length})</div>
    ))

    return (
      <div className="">
        {header}

        <div className="flex items-center justify-center mb-4">
          {tabs}
        </div>

        <SongList
          songs={this.state.selected}
          locked={song => this.updateState(song)}
          unlocked={song => this.updateState(song)}
          done={song => this.updateState(song)}
          undone={song => this.updateState(song)}
        />
      </div>
    );
  }

  updateState(song) {
    const songs = this.state.songs.map(s => s.id === song.id ? song : s);

    const toBeDone = songs.filter(song => song.Status === 'TBD');
    const locked = songs.filter(song => song.Status === 'Elkezdve');
    const done = songs.filter(song => song.Status === 'Kész');

    const selected = song.Status === 'TBD' ? toBeDone : song.Status === 'Elkezdve' ? locked : toBeDone;

    this.setState({
      songs,
      done,
      locked,
      toBeDone,
      selected,
    })
  }
}

export default App;
