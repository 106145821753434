import React from 'react';
import Song from '../Song';
import SongItem from "../song-item/SongItem";

class SongList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { songs: props.songs, selected: null };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.songs !== state.songs) {
      return { songs: props.songs };
    }
    return null;
  }

  select(id) {
    if (id === this.state.selected) {
      return;
    }

    this.setState({ selected: id });
  }

  unselect() {
    this.setState({ selected: null });
  }

  render() {
    const songItems = this.state.songs.map((song) =>
      <li key={song.id} className={'p-2 border-b border-gray-400 cursor-default ' + (this.isSelected(song) && 'bg-orange-50')} onClick={() => this.select(song.id)}>
        {this.isSelected(song) && (
          <div className='my-2 flex items-center'>
            {this.isToDo(song) ? <button type='button' onClick={() => this.lock(song.id)} className='py-1 px-2 rounded bg-orange-600 text-orange-100'>Elkezdem</button> : undefined}
            {this.isLocked(song) ? <button type='button' onClick={() => this.unlock(song.id)} className='py-1 px-2 rounded bg-gray-700 text-gray-100'>Mégsem</button> : undefined}
            {this.isLocked(song) ? <a href={'https://services.planningcenteronline.com/songs?order=title&page=1&per_page=100&text=' + encodeURIComponent(song.Title)} className='mx-2 py-1 px-2 rounded bg-green-500 text-green-100' target='_blank' rel='noreferrer'>PCO</a> : undefined}
            {this.isLocked(song) ? <a href={'https://www.youtube.com/results?search_query=' + encodeURIComponent(song.Title.split('(').shift().trim())} target='_blank' rel='noreferrer' className='mx-2 py-1 px-2 rounded bg-red-700 text-red-100'>YouTube</a> : undefined}
            {this.isLocked(song) ? <button type='button' onClick={() => this.done(song.id)} className='py-1 px-2 rounded bg-green-700 text-green-100'>Kész!</button> : undefined}
            {this.isDone(song) ? <button type='button' onClick={() => this.undone(song.id)} className='py-1 px-2 rounded bg-gray-700 text-gray-100'>Mégsem</button> : undefined}
            <button type='button' onClick={() => this.unselect()} className='py-1 px-2 rounded-full w-8 h-8 bg-orange-600 text-orange-100 ml-auto'>&times;</button>
          </div>
        )}
        <SongItem song={song} selected={this.isSelected(song)} select={id => this.select(id)} />
      </li>
    );

    return (
      <ul className="">{songItems}</ul>
    );
  }

  async lock(id) {
    try {
      const song = await Song.lock(id)

      this.props.locked(song);
    } catch (e) {
      console.error(e.message);
      // TODO display error on UI
    }
  }

  async unlock(id) {
    try {
      const song = await Song.unlock(id)

      this.props.unlocked(song);
    } catch (e) {
      console.error(e.message);
      // TODO display error on UI
    }
  }

  async undone(id) {
    try {
      const song = await Song.undone(id)

      this.props.undone(song);
    } catch (e) {
      console.error(e.message);
      // TODO display error on UI
    }
  }

  async done(id) {
    try {
      const song = await Song.done(id)

      this.props.done(song);
    } catch (e) {
      console.error(e.message);
      // TODO display error on UI
    }
  }

  isSelected(song) {
    return song.id === this.state.selected;
  }

  isToDo(song) {
    return song.Status === 'TBD';
  }

  isLocked(song) {
    return song.Status === 'Elkezdve';
  }

  isDone(song) {
    return song.Status === 'Kész';
  }
}

export default SongList;
