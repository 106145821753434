import React from 'react';

class CopiableText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: props.label || 'MICSODA?',
      value: props.value || '',
      canCopy: navigator && navigator.clipboard,
    }
  }

  copy() {
    if (!this.state.canCopy) {
      console.error('Copying to clipboard is not supported in your browser!');
      // TODO display error on UI
      return;
    }
    navigator.clipboard.writeText(this.state.value);
  }

  render() {
    return (
      <div className='grid grid-flow-col auto-cols-min gap-4 overflow-hidden'>
        <div className='w-24 whitespace-nowrap'>{this.state.label}</div>
        <div className='w-18'><button type="button" className='rounded px-2 bg-orange-800 text-orange-200' onClick={() => this.copy()}>Másol</button></div>
        <div className='whitespace-nowrap overflow-hidden text-ellipsis'>{this.state.value}</div>
      </div>
    )
  }
}

export default CopiableText;
