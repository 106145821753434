import React from 'react';
import CopiableText from '../CopiableText';

class SongItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      song: props.song,
      selected: !!props.selected,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selected !== props.selected) {
      return { selected: props.selected };
    }

    return null;
  }

  render() {
    const { song, selected } = this.state;

    return (
      <div className="">
        <div className={"flex items-center justify-between pointer-cursor " + (!selected && 'opacity-50')}>
          <span className="text-left w-12 text-gray-400 inline-block">#{song.id}</span>
          <span className='flex-grow'>{song.Title}</span>
          <span className='text-gray-500'>{song.Version}</span>
        </div>

        <div className={'flex flex-col gap-2 mt-4 pt-4 pb-4 border-t border-orange-800 ' + (!selected ? 'hidden' : undefined)}>

          <CopiableText label='Cím' value={this.state.song.Title} />
          <CopiableText label='CCLI' value={this.state.song.CCLI} />
          <CopiableText label='BPM (tempó)' value={this.state.song.BPM} />
          <CopiableText label='Hangnem' value={this.state.song.Key} />
          <CopiableText label='Hossz' value={this.state.song.Length} />
          <CopiableText label='Címkék' value={this.state.song.Tags} />
          {!this.state.song.NoChart && <CopiableText label='Kotta' value={this.state.song.ChordPro} />}
          {!this.state.song.NoChart && <CopiableText label='Dalszöveg' value={this.state.song.Lyrics} />}
          {this.state.song.NoChart && (
            <div className='text-red-600'>
              Nincs elérhető kotta, illetve dalszöveg. Nézd meg a PCO-ban, lehet, hogy van PDF vagy DOC csatolmány!
              <a href={'https://services.planningcenteronline.com/songs?order=title&page=1&per_page=100&text=' + encodeURIComponent(this.state.song.Title)}
                className='text-orange-500 underline ml-4'
                target='_blank'
              >Keresés a PCO-ban</a>
            </div>
          )}
          {this.state.song.ManualCorrection && <div className='text-red-600'>A kottát kézzel javítani kell még valakinek! Tedd meg, vagy jelezd Mánuelnek!</div>}
        </div>
      </div>
    );
  }
}

export default SongItem;
