import environment from './env';
import axios from 'axios';

async function doAction(action, id = '') {
  try {
    const resp = await axios.get(environment.apiBase, { params: { action, id } });

    return resp.data;
  } catch (e) {
    console.error(e);

    return null;
  }
}

const Song = {

  index: async () => {
    const { data } = await doAction('index');

    return (data || []).map((i, id) => ({ ...i, id }))
  },

  lock: async (id) => {
    const { data } = await doAction('lock', id);

    console.log('Locked #%s.', id, data);

    if (data.message) {
      throw new Error(data.message)
    }

    return { ...data, id };
  },

  unlock: async (id) => {
    const { data } = await doAction('unlock', id);

    console.log('Unlocked #%s.', id, data);

    if (data.message) {
      throw new Error(data.message)
    }

    return { ...data, id };
  },

  undone: async (id) => {
    const { data } = await doAction('undone', id);

    console.log('Undone #%s.', id, data);

    if (data.message) {
      throw new Error(data.message)
    }

    return { ...data, id };
  },

  done: async (id) => {
    const { data } = await doAction('done', id);

    console.log('Done #%s.', id, data);

    if (data.message) {
      throw new Error(data.message)
    }

    return { ...data, id };
  },

}

export default Song;
